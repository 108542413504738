<template>
  <div class="news outerFrame" v-if="navData">
    <!-- <button @click="contenttoken">获取token</button> -->
    <div class="searchFind">
      <div class="search" @click="$router.push('/searchPage')">
        <van-icon name="search" />
        <span>请输入搜索内容</span>
      </div>
      <div class="signIn" @click="signCon">
        <van-icon name="notes-o" />
        <span>签到</span>
      </div>
    </div>
    <div class="xinContent">
      <slidepage
        :navData="navData"
        @indexChanged="indexChanged"
        @navChange="navChange"
        :navStyle="navStyle"
        :designatedLocation="0"
      >
        <template v-for="(item,index) in navData" v-slot:[slide+index]>
          <div :key="index" class="height">
            <Homenewslist
              v-if="item.navigationSign==='TJ'"
              :myIndex="index"
              :datalink="item"
              :currentdisplay="nowBlock"
              :middleNav="middleNav"
              :appName="params.appName"
              :progressivedata="progressivedata"
              :statistics="statistics"
              @refresh="refresh"
            ></Homenewslist>
            <Homenewslist
              v-else
              :myIndex="index"
              :datalink="item"
              :currentdisplay="nowBlock"
              :middleNav="middleNav"
              :appName="params.appName"
            ></Homenewslist>
          </div>
        </template>
      </slidepage>
    </div>
  </div>
</template>

<script>

import slidepage from "./SlidePage";
import Homenewslist from "./Homenewslist";
import Homeapi from "@/api/home";
import useApi from '@/api/auth'
import { Toast } from 'vant';
import PersonApi from "@/api/person";
export default {
  data() {
    return {
      slide: 'slide',
      navData: [], // 导航条数据
      navStyle: { background: "#fff" }, //导航背景
      params: {
        appName: this.appName,
      },
      isrefresh: false,
      statistics: null,
      middleNav: null,
      progressivedata: null,
      nowBlock: null
    };
  },
  components: {
    slidepage,
    [Toast.name]: Toast,
    Homenewslist
  },
  created() {

  },
  mounted() {
    // 如果有企业信息外面的页面不需要里面的token
    if (window.localStorage.enterpriseInfo && window.localStorage.enterpriseInfo != 'undefined') {
      window.localStorage.userinfo = '';
    }

    this.navigationData()
    this.datastatistics()
    this.Homeactivitylist()
    if (this.$route.query.cid) {
      if (localStorage.cid) {
        localStorage.removeItem('cid')
      }
      localStorage.cid = this.$route.query.cid
    }
  },
  methods: {
    // 签到
    signCon() {
      if (this.isToken()) { // 登入可以签到
        const data = {
          code: 1,
          memberId: JSON.parse(window.localStorage.personalInformation).id,
        };
        PersonApi.addSignIn(data).then((res) => {
          if (res.success) {
            Toast.success("签到成功");
          } else {
            Toast(res.message);
          }
        });
      }
    },
    // 测试token
    contenttoken() {
      let params = {
        id: 79,
        expireTime: 1599118946000,
        refreshToken: "",
        token: ""
      }
      this.currency('updateUserinfo', JSON.stringify(params));
    },
    //首页上拉刷新加载
    refresh() {
      this.datastatistics()
      this.Homeactivitylist()
    },
    // 导航改变了
    indexChanged(index) {
      this.nowBlock = index
    },
    navChange(allData) {
      this.blockNav = allData;
      this.isrefresh = false;
      this.$nextTick(() => {
        this.isrefresh = true;
      });
    },
    // 接口api 
    navigationData() {
      let that = this
      let params = {
        appName: that.params.appName
      }
      Homeapi.getAppNavigtion(params).then(res => {
        // console.log(res);
        if (res.success) {
          this.middleNav = res.data.MIDDLE
          if (this.middleNav && this.middleNav.length) {
            for (let i in this.middleNav) {
              if (this.middleNav[i].navigationSign === 'meitibaodao') {
                if (localStorage.serveProjectId) {
                  localStorage.removeItem("serveProjectId", this.middleNav[i].columnId);
                }
                localStorage.setItem("serveProjectId", this.middleNav[i].columnId);
              }
            }
          }
          this.navData = res.data.TOP;
          for (let i = 0; i < res.data.TOP.length; i++) {
            this.navData[i].status = false
          }
        } else {
          Toast.fail(res.message)
        }
        // console.log(res)
      })
    },
    // 数据统计
    datastatistics() {
      // 指尖东至不需要数据统计
      let that = this
      if (that.appName == "指尖东至") {
        that.statistics = null;
        // console.log(this.statistics)
        return false;
      }
      let params = {
        appName: this.params.appName
      }
      Homeapi.Homestaticdata(params).then(res => {
        if (res.success) {
          this.statistics = res.data
          console.log("statistics", this.statistics)
        } else {
          Toast.fail(res.message)
        }

      })
    },
    // 招募活动
    Homeactivitylist() {
      let that = this
      let params = {
        appName: this.params.appName,
        page: 1,
        size: 2
      }
      Homeapi.Homeactivitylist(params).then(res => {
        if (res.success) {
          console.log('招募', res.data.items)
          this.progressivedata = res.data.items
        } else {
          Toast.fail(res.message)
        }

      })
    }
  },
};
</script>


<style scoped>
.searchFind {
  width: 100%;
  height: 0.75rem;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  box-sizing: border-box;
}

@media screen and (max-width: 414px) {
  i.van-icon.van-icon-search {
    margin-right: 5px;
    font-size: 25px;
  }
  .search {
    height: 0.58rem;
    width: 80%;
    border-radius: 100px;
    background: #f7f8fa;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .search span {
    color: #999;
    font-size: 13px;
  }
  .signIn {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .signIn > span {
    font-size: 0.275rem;
  }
}

@media screen and (min-width: 414px) {
  i.van-icon.van-icon-search {
    margin-right: 5px;
    font-size: 16px;
  }

  .search {
    height: 0.5rem;
    width: 80%;
    border-radius: 100px;
    background: #f7f8fa;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .search span {
    color: #999;
  }
  .signIn {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .signIn > span {
    font-size: 0.22rem;
  }
}

i.van-icon.van-icon-notes-o {
  font-size: 0.3rem;
  margin-right: 2px;
}
.xinContent {
  height: calc(100% - 54px);
}
.height {
  height: 100%;
}
.indexList {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-container {
  --swiper-pagination-color: #1377e2;
}
.indexList .swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.swiperBox {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}
.outLinkerContent {
  width: 100%;
  padding-bottom: 20%;
  position: relative;
  /* margin-top: 15px; */
}
.outLinkerContentBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.outLinkerPer {
  width: 20%;
  height: 80%;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.outLinkerPerPer {
  width: 100%;
  height: 56%;
}
.outLinkerPerPer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.outLinkerPerTitle {
  width: 100%;
  /* line-height: 25px; */
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.class_body {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  margin: 0.21rem 0.28rem;
}
.class_list {
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
  font-size: 0.13rem;
}
.class_content {
  /* width: 28vw; */
  /* text-align: center; */
  /* margin: 2vw 0; */
  font-size: 0.26rem;
  /* font-weight: bold; */
}
.class_btn {
  width: 16vw;
  background: #f61729;
  color: #fff;
  padding: 1px 5px;
  border-radius: 7px;
}
.class_list img {
  width: 9vw;
}
.data_all {
  height: 2.28rem;
  margin: 0 0.28rem 4vw;
  background: #ffffff;
  box-shadow: 1px 4px 3px 0px rgba(114, 44, 36, 0.05);
  border-radius: 10px;
}
.data_all img {
  width: 100%;
}
.sum_body {
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  margin-top: -3vw;

  padding: 0.2rem;
}
.sum_list {
  width: 20vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 2vw 0;
  border-right: 1px solid #bfbfbf;
  padding: 0 5px;
}
.sum_list:last-child {
  border-right: 0;
}
.sum_content {
  margin: 1vw 0;
  font-size: 0.31rem;
  font-weight: bold;
}

.wenming_img {
  display: flex;
  align-items: center;
  font-size: 0.31rem;
  font-weight: bold;
  padding: 2vw 3vw;
}
.wenming_img img {
  width: 6vw;
  margin-right: 5px;
}
.wenming_body {
  display: flex;
  justify-content: space-around;
}
.wenming_wrapper {
  position: relative;
}
.wenming_list {
  width: 43vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
  border: 1px solid #dcdcdc;
}
.zhaomu_img {
  width: 17vw;
  position: absolute;
  top: 0;
}
.wenming_list img {
  width: 100%;
}
.wenming_content {
  /* width: 28vw; */
  /* text-align: center; */
  margin: 0.1rem 0;
  font-size: 0.3rem;
  padding-left: 2vw;
  font-weight: bold;
}
.wenming_content_two {
  margin: 0.1rem 0;
  font-size: 0.23rem;
  padding-left: 2vw;
  color: #939393;
}
.indexList .haveContent {
  width: 100%;
  height: 100%;
}
.indexList .thumbImg {
  width: 100%;
  height: 100%;
  position: relative;
}
.indexList .thumbImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.indexList .swiper-pagination-bullet {
  width: 20px;
  height: 3px;
  border-radius: 0px;
  background: #ababab;
  opacity: 1;
}
.indexList .swiper-pagination-bullet-active {
  background: #fff;
}
.indexList .titleBunner {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0px;
  color: #333;
  font-size: 0.28rem;
  font-weight: 700;
  padding: 0px 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #e6e6e6;
  padding-top: 5px;
}
.titleIndex {
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 0.24rem;
  color: #ababab;
}
.titleIndex span {
  color: red;
  font-weight: 100;
}
.borderBox {
  /* border:  solid#e6e6e6; */
  /* width: 100%; */
  /* margin-top: -50px; */
}
/* .newsBox{
        margin-top: 18px;
    } */
.outSwiper {
  /* margin-top: 5px; */
  margin: 10px auto;
}
.my-swipe .van-swipe-item {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.imgBox {
  /* margin: 0 1px; */
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imgBox img {
  width: 35px;
  height: 35px;
}
.outerTitle {
  font-size: 14px;
}
</style>
